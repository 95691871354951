
import { reactive } from 'vue'
import _list from '@/assets/js/list'
import _model from '@/static/model/account/bill'

export default {
	components: {},

	setup(props, context) {
		return {
			_model: _model,
			options: reactive({}),
			setting: reactive({
				moduleName: '账单记录',
				searchApi: '/Settlement/Bill/billList',
				moduleType: 'full',
				tableFirstType: 'selection',
			}),
			list: reactive({search: {mchType: 1}, data: []}),
			
		}
	},
	mounted() {
		this._load(this, _list)
		this._listSearch()
	},
	methods: {
	}
}
